import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import Link from "../components/LinkWithTransitionLock";

import InnerHeight from 'ios-inner-height'

import TaipeiMap from "../components/LoadableTaipeiMap";
// import AnimatedLinksObjs from "../components/animatedLink";
import Page_Footer from "../components/page_footer";

import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var link = document.querySelectorAll(".text_link");
    var cursor = document.querySelector(".cursor");
    var visual = document.querySelector(".link_current_visual");
    var visual_inner = visual.querySelector(".visual_inner");
    var timer;

    var num = Math.floor(Math.random() * 10) + 1;
    num *= Math.floor(Math.random() * 2) == 1 ? 1 : -1;


    const hero_link_animate = function(e) {
      var target = this.querySelector(".text_inner");
      var target_value = this.dataset.topic;
      var target_visual = this.dataset.visual;
      var { offsetX: x, offsetY: y } = e,
        { offsetWidth: width, offsetHeight: height } = this,
        move = 25,
        xMove = (x / width) * (move * 2) - move,
        yMove = (y / height) * (move * 2) - move,
        xShadow = (x / width) * (move * 1) - move * 1,
        yShadow = (y / height) * (move * 1) - move * 1;

      target.style.transform = `translate(${xMove}px, ${yMove}px) scale(1.1)`;
      // visual.style.transform = `translate(${xMove}px, ${yMove}px)`;
      // target.style.textShadow = xShadow + "px " + yShadow + "px " + "2px rgba(0,0,0,.2))";
      // target.style.setProperty('-webkit-filter', 'drop-shadow(' + xShadow + 'px ' + yShadow + 'px ' + 0 + 'px var(--col_black_opacity))');
      // cursor.classList.add(target_value);
      visual_inner.style.backgroundImage = `url(${target_visual})`;

      clearTimeout(timer);
      // timer = setTimeout(function() {
      //   if (!visual.classList[2]) {
      //     visual.classList.remove(visual.classList[2]);
      //     visual.classList.add("hero_visual_in", target_value);
      //     visual.classList.remove("hero_visual_out");
      //   }
      // }, 200);

      // if (!visual.classList[2]) {
        visual.classList.remove(visual.classList[2]);
        visual.classList.add("hero_visual_in", target_value);
        visual.classList.remove("hero_visual_out");
      // }

      if (e.type === "mouseleave") {
        target.style.transform = `translate(0px, 0px) scale(1)`;
      //   // target.style.setProperty('-webkit-filter', 'drop-shadow(2px 2px 0px var(--col_gray_darken))');
        // cursor.classList.remove(target_value);

        visual.classList.remove("hero_visual_in");
        visual.classList.add("hero_visual_out", target_value);

        setTimeout(function() {
          visual.classList.remove("hero_visual_out");
        //   visual.classList.remove(target_value);
        //   var num = Math.floor(Math.random() * 9) + 1;
        //   num *= Math.floor(Math.random() * 1.5) == 1 ? 1 : -1;
        //   visual.style.transform = `rotate(${num}deg)`;
        }, 1200);
      }
    };

    link.forEach((b) => b.addEventListener("touchstart", hero_link_animate));
    link.forEach((b) => b.addEventListener("touchend", hero_link_animate));

    link.forEach((b) => b.addEventListener("mousemove", hero_link_animate));
    link.forEach((b) => b.addEventListener("mouseleave", hero_link_animate));

    
    var process_bar = document.querySelector(".process_bar");
    var load_cover = document.querySelector('.load_cover');
    var hero = document.querySelector('.hero');
    var paragraph_a = document.querySelector('.paragraph_a');
    var paragraph_b = document.querySelector('.paragraph_b');
    var paragraph_c = document.querySelector('.paragraph_c');
    var paragraph_d = document.querySelector('.paragraph_d');
    
    load_cover.style.height = window.innerHeight + 'px';
    
    let count = 1;
    window.timer = window.setInterval(function(){
      count++;
      if ( count <= 95 ) {
        count = count + 1;
        process_bar.style.bottom = count + '%';
      } else {
        window.clearInterval(window.timer);
        // console.log(count);

        gsap.to(load_cover, {
          opacity: 0,
          duration: 2,
          ease: Expo.easeOut
        });
        gsap.to(load_cover, {
          zIndex: '-999',
          delay: 2,
        });

        gsap.fromTo(paragraph_a, {
          opacity: 0,
          scale: 1.1,
          rotation: 3,
          transformOrigin: 'top left',
          y: '100%'
        }, {
          opacity: 1,
          y: '0%',
          scale: 1,
          rotation: 0,
          duration: 2,
          delay: .6,
          ease: Expo.easeOut
        });
    
        gsap.fromTo(paragraph_b, {
          opacity: 0,
          scale: 1.1,
          rotation: 3,
          transformOrigin: 'top left',
          y: '100%'
        }, {
          opacity: 1,
          y: '0%',
          scale: 1,
          rotation: 0,
          duration: 2,
          delay: 1,
          ease: Expo.easeOut,
        });
    
        gsap.fromTo(paragraph_c, {
          opacity: 0,
          scale: 1.1,
          rotation: 3,
          transformOrigin: 'top left',
          y: '100%'
        }, {
          opacity: 1,
          y: '0%',
          scale: 1,
          rotation: 0,
          duration: 2,
          delay: 1.4,
          ease: Expo.easeOut,
        });
    
        gsap.fromTo(paragraph_d, {
          opacity: 0,
          scale: 1.1,
          rotation: 3,
          transformOrigin: 'top left',
          y: '100%'
        }, {
          opacity: 1,
          y: '0%',
          scale: 1,
          rotation: 0,
          duration: 2,
          delay: 1.8,
          ease: Expo.easeOut,
        });

      }
    }, 30);



    // gsap.to(paragraph_a, {
    //   delay: .2,
    //   className: '+=paragraph paragraph_a in'
    // });
    // gsap.to(paragraph_a, {
    //   delay: 1.6,
    //   className: '+=paragraph paragraph_a in main_in'
    // });

    // gsap.to(paragraph_b, {
    //   delay: .4,
    //   className: '+=paragraph paragraph_b in'
    // });
    // gsap.to(paragraph_b, {
    //   delay: 2,
    //   className: '+=paragraph paragraph_b in main_in'
    // });

    // gsap.to(paragraph_c, {
    //   delay: .8,
    //   className: '+=paragraph paragraph_c in'
    // });
    // gsap.to(paragraph_c, {
    //   delay: 2.4,
    //   className: '+=paragraph paragraph_c in main_in'
    // });

    // gsap.to(paragraph_d, {
    //   delay: 1.2,
    //   className: '+=paragraph paragraph_d in'
    // });
    // gsap.to(paragraph_d, {
    //   delay: 2.8,
    //   className: '+=paragraph paragraph_d in main_in'
    // });


		const hero_move = function(e){
			var w      = hero.offsetWidth;
			var h      = hero.offsetHeight;
			var center = { x: w/2, y: h/2 };
			var shadowLimit = 200;

			var evX = e.clientX;
			var evY = e.clientY;

			var shadowX = (center.x - evX) / 20;
			var shadowY = (center.y - evY) / 20;

			shadowX = (shadowX > shadowLimit) ? shadowLimit : shadowX;
			shadowX = (shadowX < shadowLimit*-1) ? shadowLimit*-1 : shadowX;
			shadowY = (shadowY > shadowLimit) ? shadowLimit : shadowY;
			shadowY = (shadowY < shadowLimit*-1) ? shadowLimit*-1 : shadowY;

			TweenMax.to(hero, 2, {
				rotationY: -0.02 * shadowX,
				rotationX: 0.01 * shadowY,
				transformPerspective: 500,
				transformOrigin: "center center -200",
				x: .75 * shadowX,
				y: .5 * shadowY,
				// scale: 1,
				ease: Expo.easeOut
			})
			
    }
    
		window.addEventListener('mousemove', hero_move);

    document.querySelector('#link_main_video').addEventListener('click', function(){
      document.querySelector('.home_logo').classList.add('video_playing');
    });

    document.querySelector('.home_logo').addEventListener('click', function(){
      document.querySelector('.home_logo').classList.remove('video_playing');
    });

    // document.querySelector('#link_taipei').addEventListener('click',function(e){
    //   window.gtag('event', 'event', '社宅地圖', '點擊', '打開社宅地圖');
    // });
  }

  componentWillUnmount() {}

  render() {


    return (
      <>

        <div className="hero content_wrap">
          <div className="paragraph paragraph_a">
            <div
              id="link_taipei"
              className="text_link link_sty_sub link_taipei"
              data-topic="topic_map"
            >
              <div className="text_inner">臺北</div>
            </div>
            <div className="text">是</div>
            <Link to="/declaration/">
              <div
                id="link_declaration"
                className="text_link link_sty_main link_declaration"
                data-topic="topic_a"
              >
                <div className="text_inner">所有人的家園</div>
              </div>
            </Link>
            <div className="text">，不遺落邊緣</div>
            <div className="break">
              <br />
            </div>
            <div className="text">弱勢的人，關心他們和腳下的這片土地
              <Link to="/main_video/" className="main_video">
                <div id="link_main_video" className="text_link">
                  <div className="text_inner">
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='36'
                      height='36'
                      viewBox='0 0 36 36'
                    >
                      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
                        <path
                          fill='#501111'
                          fillRule='nonzero'
                          d='M18 0C8.056 0 0 8.056 0 18s8.056 18 18 18 18-8.056 18-18S27.944 0 18 0zm8.176 19.687l-11.785 7.1C13.333 27.404 12 26.61 12 25.31V10.69c0-1.294 1.326-2.096 2.39-1.477l11.786 7.521c1.099.647 1.099 2.313 0 2.953z'
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="paragraph paragraph_b">
            <div className="text">該如何想像在這城市中</div>
            <Link to="/discourse/">
              <div
                id="link_discourse"
                className="text_link link_sty_main link_discourse"
                data-topic="topic_a"
              >
                <div className="text_inner">一起生活的可能？</div>
              </div>
            </Link>
            <div className="break">
              <br />
            </div>
            <div className="text">召喚主動追尋的行動？</div>
          </div>
          <div className="paragraph paragraph_c">
            <div className="text">臺北社宅公共藝術線上展，在虛擬中探問：</div>
            <div className="break">
              <br />
            </div>
            <Link to="/project_b/">
              <div
                id="link_project_b"
                className="text_link link_sty_sub link_project_b"
                data-topic="topic_b" data-visual={require('../images/home/project_b.png')}
              >
                <div className="text_inner">孤獨的共感</div>
                {/* <div className="releasedate">4/27</div> */}
              </div>
            </Link>
            <div className="text">，共創</div>
            <Link to="/project_c/">
              <div
                id="link_project_c"
                className="text_link link_sty_sub link_project_c"
                data-topic="topic_c" data-visual={require('../images/home/project_c.png')}
              >
                <div className="text_inner">在地編輯</div>
              </div>
            </Link>
            <div className="text">，交換</div>
            <Link to="/project_a/">
              <div
                id="link_project_a"
                className="text_link link_sty_sub link_project_a"
                data-topic="topic_a" data-visual={require('../images/home/project_a.png')}
              >
                <div className="text_inner">天賦與善意</div>
              </div>
            </Link>
            <div className="text">，放送</div>
            <Link to="/project_d/">
              <div
                id="link_project_d"
                className="text_link link_sty_sub link_project_d"
                data-topic="topic_d" data-visual={require('../images/home/project_d.png')}
              >
                <div className="text_inner">綠色實境秀</div>
                {/* <div className="releasedate">5/4-8</div> */}
              </div>
            </Link>
            <div className="text">。</div>
          </div>
          <div className="paragraph paragraph_d">
            <div className="text">透過網上</div>
            <Link to="/blog/">
              <div
                id="link_blog"
                className="text_link link_sty_main link_blog"
                data-topic="topic_c"
              >
              {/* <div
                id="link_blog"
                className="text_link link_sty_main link_blog upcoming"
                data-topic="topic_c"
              > */}
                <div className="text_inner">社群擾動</div>
                {/* <div className="releasedate">即將登場</div> */}
              </div>
            </Link>
            <div className="text">
              ，希望與大眾一起找尋藝術面對現實複雜多變的
            </div>
            <Link to="/other/">
              <div
                id="link_other"
                className="text_link link_sty_main link_other"
                data-topic="topic_c"
              >
                <div className="text_inner">路徑</div>
              </div>
            </Link>
            <div className="text">，</div>
            <div className="break">
              <br />
            </div>
            <div className="text">描繪未來藝術進駐社宅的都市生活藍圖。</div>
          </div>
        </div>

        <TaipeiMap />

        <div className="link_current_visual">
          <div className="visual_inner"></div>
        </div>



        <div className="load_cover">
          <div className="text_inner">
            <div className="main_title">HOME TO ALL</div>
            <div className="sub_title">我們也可以這樣生活</div>
            <div className="process_bar"></div>
          </div>
        </div>
        
        
      </>
    );
  }
}

export default Page;
